import React from 'react'

function Video() {
  return (
    <div>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/qg3Bw6IwI7I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
  )
}

export default Video