import './App.css';
import Portrait from './Portrait';
import Video from './Video';

function App() {
  return (
    <div className="Portarit">
      <header className="Portrait-header">
        <h1>2020-2022</h1>
       <Portrait/>
       <Video/>
      </header>
    </div>
  );
}

export default App;
