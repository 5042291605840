import logo from './rVaGvEi.png'

function Portrait() {
  return (
    <div>
        <a href='https://imgur.com/rVaGvEi' target='_blank' rel='noreferrer'><img src={logo} alt='tokentupe - finnish tupe platform'/></a>
    </div>
  )
}

export default Portrait